<template>
  <div
    v-if="localeStore?.isUsLocale"
    id="pp-pay-later-message"
    class="pp-message"
    data-pp-message
    :data-pp-placement="placement"
    :data-pp-amount="value"
  />
</template>

<script setup lang="ts">
import { useLocaleStore } from '~/store/locale';
defineProps({
  value: {
    type: Number,
    required: true,
  },
  placement: {
    type: String,
    required: false,
    default: 'product',
  },
});
const localeStore = useLocaleStore();
onMounted(() => {
  if (localeStore?.isUsLocale && !(window as any)?.paypal?.version) {
    const s = document.createElement('script');
    s.defer = true;
    // TODO - check paypal site to ensure it is setup for different currencies
    s.src = `https://www.paypal.com/sdk/js?client-id=ASPnzGKv_posw1tvk4ge2w76G0v_rQfPv9GFTgdfa0Y5Rsuwu-ycnYHULsbr74y40pctoGFAqU3NN7SN&components=messages&currency=${localeStore.currencyCode}`; // components=buttons,messages
    document.body.appendChild(s);
  }
});
</script>
